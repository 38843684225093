import NavBar from "./components/navbar/Navbar";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound/notfound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home />
            </>
          }
        />
        <Route
          path="/day-1"
          element={
            <>
              <Home />
            </>
          }
        />
        <Route
          path="/day-2"
          element={
            <>
              <Home />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <NavBar />
              <NotFound />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
