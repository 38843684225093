import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";

function NavBar() {
  return (
    <Navbar expand="lg" className="py-3 navbar">
      <Container>
        <Navbar.Brand href="#" className="me-lg-5"></Navbar.Brand>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link href="#action1"></Nav.Link>
            <Nav.Link href="#action1" className="text-white px-lg-3">
              BERANDA
            </Nav.Link>
            <Nav.Link href="#action1" className="text-white px-lg-3">
              LINE UP
            </Nav.Link>
            <Nav.Link href="#action1" className="text-white px-lg-3">
              BELI TIKET
            </Nav.Link>
            <Nav.Link href="#action1" className="text-white px-lg-3">
              PERTANYAAN UMUM
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
