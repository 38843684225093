import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { benefit, csNumbers } from "../components/utils";
import { getLink } from "../helper";

const Home = () => {
  return (
    <Container className="home" fluid>
      <Button
        className="btn-goto-wa"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=${
              csNumbers[Math.floor(Math.random() * csNumbers.length)]
            }&text=Halo+Admin+Hanan+Nusantara%2C+Saya+ingin+bertanya+tentang+Promo+Hanan+Nusantara&type=phone_number&app_absent=0`
          )
        }
      />
      <Col style={{ width: "100%", zIndex: "0" }}>
        <Image
          src={require("../images/mobile/hero-banner.png")}
          style={{ width: "100%" }}
        />
      </Col>
      <Col className="section-2 text-center">
        <Button
          className="btn-hero-wa mb-4"
          onClick={() =>
            getLink(
              `https://api.whatsapp.com/send/?phone=${
                csNumbers[Math.floor(Math.random() * csNumbers.length)]
              }&text=Halo+Admin+Hanan+Nusantara%2C+Saya+ingin+bertanya+tentang+Promo+Hanan+Nusantara&type=phone_number&app_absent=0`
            )
          }
        >
          <Image
            src={require("../images/mobile/button-text.png")}
            style={{ width: "60%" }}
          />
        </Button>
        <Image
          src={require("../images/mobile/paket-umroh.png")}
          style={{ width: "60%" }}
          className="mb-4"
        />
        <Col className="d-flex justify-content-center px-2 mb-3">
          <Col
            xs={4}
            onClick={() =>
              getLink(
                `https://api.whatsapp.com/send/?phone=${
                  csNumbers[Math.floor(Math.random() * csNumbers.length)]
                }&text=Halo+Admin+Hanan+Nusantara%2C+Saya+ingin+bertanya+tentang+Promo+Hanan+Nusantara+Paket+9D&type=phone_number&app_absent=0`
              )
            }
          >
            <Image
              src={require("../images/mobile/promo-2.png")}
              style={{ width: "90%" }}
            />
          </Col>
          <Col
            xs={4}
            onClick={() =>
              getLink(
                `https://api.whatsapp.com/send/?phone=${
                  csNumbers[Math.floor(Math.random() * csNumbers.length)]
                }&text=Halo+Admin+Hanan+Nusantara%2C+Saya+ingin+bertanya+tentang+Promo+Hanan+Nusantara+Paket+12D&type=phone_number&app_absent=0`
              )
            }
          >
            <Image
              src={require("../images/mobile/promo-1.png")}
              style={{ width: "90%" }}
            />
          </Col>
          <Col
            xs={4}
            onClick={() =>
              getLink(
                `https://api.whatsapp.com/send/?phone=${
                  csNumbers[Math.floor(Math.random() * csNumbers.length)]
                }&text=Halo+Admin+Hanan+Nusantara%2C+Saya+ingin+bertanya+tentang+Promo+Hanan+Nusantara+Paket+16D&type=phone_number&app_absent=0`
              )
            }
          >
            <Image
              src={require("../images/mobile/promo-3.png")}
              style={{ width: "90%" }}
            />
          </Col>
        </Col>
        <Col>
          <Image
            src={require("../images/mobile/hotel-1.png")}
            style={{ width: "100%", marginBottom: "2rem" }}
          />
          <Image
            src={require("../images/mobile/hotel-2.png")}
            style={{ width: "100%" }}
          />
        </Col>
        <Col className="box-fasilitas">
          <Image
            src={require("../images/mobile/fasilitas-umroh.png")}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          {benefit.map((item, i) => {
            return (
              <Row key={i} className="text-white mb-2">
                <Col className="number-circle" xs={1}>
                  {i + 1}
                </Col>
                <Col>
                  <h5>{item.benefit}</h5>
                </Col>
              </Row>
            );
          })}
          <Button
            className="btn-hero-wa mb-4"
            onClick={() =>
              getLink(
                `https://api.whatsapp.com/send/?phone=${
                  csNumbers[Math.floor(Math.random() * csNumbers.length)]
                }&text=Halo+Admin+Hanan+Nusantara%2C+Saya+ingin+bertanya+tentang+Promo+Hanan+Nusantara&type=phone_number&app_absent=0`
              )
            }
          >
            <Image
              src={require("../images/mobile/button-text.png")}
              style={{ width: "60%" }}
            />
          </Button>
        </Col>
        <Col className="section-3">
          <Image
            src={require("../images/mobile/galery.png")}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          <Col>
            <Image
              src={require("../images/mobile/gal-1.png")}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <Image
              src={require("../images/mobile/gal-2.png")}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <Image
              src={require("../images/mobile/gal-3.png")}
              style={{ width: "100%", marginBottom: "10px" }}
            />
          </Col>
        </Col>
        <Image
          src={require("../images/mobile/testimoni.png")}
          style={{ width: "70%", marginBottom: "20px", marginTop: "4rem" }}
        />
        <Col className="border-yt mb-3">
          <iframe
            width="90%"
            src="https://www.youtube.com/embed/UR3DJ54lvj0"
            title="TANGIS HARU JAMA&#39;AH UMROH "
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
          ></iframe>
        </Col>
        <Col className="border-yt mb-3">
          <iframe
            width="90%"
            src="https://www.youtube.com/embed/jbtYk6Asvf4"
            title="JUANDA KEMBALI MEMBIRU!!! 250 jama&#39;ah Hanan Nusantara Kembali Ke Tanah Air"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
          ></iframe>
        </Col>
        <Col className="border-yt">
          <iframe
            width="90%"
            src="https://www.youtube.com/embed/HrHi5zUBqas"
            title="Maulid Nabi &amp; Silaturahmi Akbar | Pt Hanan Nusatara &amp; Tabung Haji Umroh | [ HIGHLIGHT ]"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Col>
      </Col>
    </Container>
  );
};

export default Home;
