const benefit = [
  {
    benefit: "Tiket PP dari Surabaya dan Jakarta",
  },
  {
    benefit: "Hotel Berbintang Setaraf",
  },
  {
    benefit: "Tour Leader & Pembimbing Ibadah",
  },
  {
    benefit: "Sertifikat Umroh Hanan",
  },
  {
    benefit: "Air Zam-Zam 5 Liter",
  },
  {
    benefit: "Makan 3x Sehari",
  },
  {
    benefit: "City Tour",
  },
  {
    benefit: "VISA",
  },
  {
    benefit: "Dan Lain-Lain...",
  },
];

const csNumbers = [
  "6282338319023",
  "6282338223542",
  "6282338223537",
  "6282338217503",
  "6282338217508",
];

const csNumber = "6281918065183";

export { benefit, csNumber, csNumbers };
